body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background-color: #f7f7f7;
  min-width: 600px;
}

#login-form {
  text-align: center;
}

.settings-module {
  padding: 20px;
  margin-bottom: 20px;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

pre {
  margin: 0;
}

html, body, #root, .App, .App > div {
  height: 100%;
  color: rgba(0,0,0,1);
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.section-title {
  padding: 20px 0 20px 0;
  font-size: 22px;
  font-weight: 300;
  color: rgba(0,0,0,0.7);
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

span.cuda-side-radio-wrapper {
  position: relative;
  display: inline-flex;
  -webkit-transition: none;
  transition: none;
  align-items: center;
  margin: 5px 0px;
}

span.cuda-side-radio-wrapper:first-child span.cuda-side-radio-toggle, 
span.cuda-side-radio-wrapper:last-child span.cuda-side-radio-toggle {
  border-right: 1px solid #DDDDDD;
}

span.cuda-side-radio-wrapper:first-child span.cuda-side-radio-toggle {
  border-left: 1px solid #DDDDDD;
  border-radius: 2px 0 0 2px;
} 

span.cuda-side-radio-wrapper:last-child span.cuda-side-radio-toggle {
  border-radius: 0 2px 2px 0;
}

span.cuda-side-radio-wrapper span.cuda-side-radio-toggle svg {
  margin-right: 5px;
}

span.cuda-side-radio-wrapper span.cuda-side-radio-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 112px;
  line-height: 40px;
  font-size: 14px;
  border: 1px solid #DDDDDD;
  border-left: 0;
  background-color: #FFFFFF;
}

span.cuda-side-radio-wrapper span.cuda-side-radio-toggle.selected {
  border: 1px solid #0088CE;
  background-color: #0088CE;
  box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5);
  color: #FFFFFF;
}

span.cuda-side-radio-wrapper input[type="radio"] {
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
}

span.cuda-side-radio-wrapper input[type="radio"]:hover {
  cursor: pointer;
}

#cuda-body {
  display: flex;
}

div.component-selector {
  min-width: 200px;
}

div.component-view {
  flex-grow: 1;
  max-width: 1020px;
}

